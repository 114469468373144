<template>
  <div />
</template>

<script>
import auth from '@/@custom/libs/auth'
import router from '@/routing/router'

export default {
  name: 'Callback',
  mounted() {
    const t = this
    auth.signinRedirectCallback().then(() => {
      t.$store.dispatch('auth/getUser')
        .then(() => {
          router.push('/')
        })
        .catch(() => {
          router.push({ name: 'error-401' })
        })
    }).catch(() => {
      // console.error(e)
    })
  },
}
</script>

<style scoped>

</style>
